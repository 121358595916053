<template>
    <div style="margin-top:44px;">
        <!-- tab -->
        <div class="layout">
            <h1 class="display-5 mb-4">我的票券</h1>
            <div class="row justify--center">
                <va-button-toggle
                class="w-100 toggleBtn"
                outline
                v-model="model"
                :options="options"
                />
            </div>
        </div>
        <!-- card -->
        <div class="mx-3 mb-2 mt-3" v-for="tk in ticketsList" :key="tk.order">
            <va-card class="mb-2" v-if="statusHandler(tk.status)">
                <va-card-title class="title ticket_title">{{tk.ticket_info.title}} </va-card-title>
                <va-card-content class="ticket_content">
                    <p>
                        <va-icon :size="16">calendar_today</va-icon>
                        {{dateFormat(tk.ticket_info.start_at)}} ~ {{dateFormat(tk.ticket_info.end_at)}}
                    </p> 
                    <!-- <p>訂單編號 #{{tk.order}}</p>  -->
                    <div class="mt-3 text-right">
                        <va-button v-if="tk.status == 1" @click="detail(tk)">使用</va-button>
                        <va-button v-else outline @click="detail(tk)">查看</va-button>
                        <!-- <va-button v-else outline icon="qr_code_2" class="mr-2" @click="detail(tk)"></va-button> -->
                    </div>
                </va-card-content>
            </va-card>
        </div>
        <!-- dialogs -->
        <modal-exchange></modal-exchange>
        <!-- <modal-alert :modal="alertModal" @close="alertModal.dialog=false"></modal-alert> -->
    </div>
</template>
<script>
import moment from 'moment'
import ModalExchange from "../components/ModalExchange.vue"
// import ModalAlert from "../components/ModalAlert.vue"
import { mapGetters,mapMutations,mapActions } from "vuex"
export default {
    components:{ ModalExchange },
    data(){
        return{
            model: 1,
            // alertModal: {
            //     dialog: false,
            //     title: '提醒',
            //     content: '此票券僅能使用一次，確定要使用票券嗎？'
            // },
            options: [
                { label: '可使用', value: 1 },
                { label: '已使用/已過期', value: 2 },
            ],
            tickets:[
                //status: 1-可使用 2-已使用 3-已過期
                {
                    title: "Skyline Film 屋頂電影院 5月台北放映",
                    start_at: "2022-05-01 00:00",
                    end_at: "2022-05-20 23:50",
                    order: "2204041132198946899500",
                    status: 1,
                },
                {
                    title: "【街遊 Hidden Taipei】 北車迷城生存指南》2022-05-28",
                    start_at: "2022-05-28 10:00",
                    end_at: "2022-05-28 12:00",
                    order: "2204041132198946899500",
                    status: 1,
                },
                {
                    title: "2022 福虎祭 - 臺虎精釀啤酒節",
                    start_at: "2022-04-20 00:00",
                    end_at: "2022-04-21 23:50",
                    order: "2204041132198946697519",
                    status: 2,
                },
                {
                    title: "2022 台北當代藝術博覽會 TAIPEI DANGDAI ART & IDEAS",
                    start_at: "2022-01-01 00:00",
                    end_at: "2022-02-28 23:50",
                    order: "2204041132198946665498",
                    status: 3,
                }
            ],
        }
    },
    computed:{
        ...mapGetters([
            'ticketsList'
        ]),
    },
    mounted(){
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
        this.getTicketsList()
    },
    methods:{
        ...mapMutations([
            'setExchangeTicket',
            'setExchangeModal'
        ]),
        ...mapActions([
            'getTicketsList'
        ]),
        dateFormat(date){
            return moment(date).format("YYYY/MM/DD HH:mm")
        },
        statusHandler(status){
            if(this.model == 1 && status == 1){
                return true
            }else if(this.model == 2 && status >= 2) {
                return true
            }else{
                return false
            }
        },
        detail(tk){
            this.setExchangeTicket(tk)
            this.setExchangeModal(true)
            // this.alertModal.dialog = true
        },
    }
}
</script>
<style scoped lang='scss'>
.toggleBtn{
    :deep(.va-button-group){
        width: 100%;
        .va-button{
            width: 100%;
        }
    }
}
.ticket{
    &_title{
        font-size: 15px;
    }
    &_content{
        width: 100%;
        padding-bottom: 16px;
        p{
            color: #777;
            font-size: 14px;
            letter-spacing: 1px;
        }
    }
}
</style>