<template>
    <va-modal
      v-model="exchangeModal"
      hide-default-actions
      overlay-opacity="0.2"
    >
      <template #header>
        <div class="row">
          <div class="flex" style="margin-left:auto;">
            <va-button icon="close" flat @click="setExchangeModal(false)"></va-button>
          </div>
        </div>
        <p class="display-5 mx-3">{{ exchangeTicket.ticket_info.title }}</p>
      </template>
      <slot>
          <va-divider orientation="center" class="tk_status">
          <span v-if="exchangeTicket.status==1" class="px-2 caption">票券使用中</span>
          </va-divider>
          <div style="position:relative;">
            <va-image v-show="exchangeTicket.status==40" class="stamp" src="/img/stamp.png"></va-image>
            <QRCodeVue3
            :class="{'qr':exchangeTicket.status!=1}"
            :width="200"
            :height="200"
            :value="`${host_url}/verify.html?id=${exchangeTicket.code}`"
            :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
            :dotsOptions="{type: 'classy'}"
            :cornersSquareOptions="{ type: ''}"
            :cornersDotOptions="{ type: ''}"
            />
          </div>
      </slot>
      <template #footer>
        <div class="row align--center" style="flex-direction:column;">
          <!-- <p class="caption mb-5">
            票券即將在 <span>{{sec}} </span>秒後自動關閉，<br/>請盡速將此頁面交由工作人員核對！
          </p> -->
          <div v-if="exchangeTicket.status==1" class="use mt-5">
            <p class="caption mb-3">請交由工作人員操作</p>
            <va-button @click="exchange">
            使用
          </va-button>
          </div>
          <p v-else class="caption mt-4">票券已使用 / 已過期</p>
          <!-- <va-button class="colse_btn" outline @click="setExchangeModal(false)">
            關閉
          </va-button> -->
        </div>
        
      </template>
    </va-modal>
</template>
<script>
import { mapGetters,mapMutations,mapActions } from 'vuex';
import QRCodeVue3 from "qrcode-vue3";
export default {
    components: {
      QRCodeVue3
    },
    data(){
        return{
            message: "Lorem 100",
            //timer
            sec: 0,
            intervel: null,
            host_url: process.env.VUE_APP_BASE_URL,
        }
    },
    computed:{
      ...mapGetters([
        'exchangeModal',
        'exchangeTicket'
      ])
    },
    watch:{
      exchangeModal(val){
        if(val && !val.qr){
          this.createTimer()
        }
      },
    },
    mounted(){
    },
    methods:{
      ...mapMutations([
        'setExchangeModal',
        'setErrorMsg'
      ]),
      ...mapActions([
        'reedemTicket'
      ]),
       //timer
        createTimer(){
            this.sec = 120
            this.count()
        },
        count(){
            clearInterval(this.intervel)
            this.intervel = setInterval(this.timer,1000)
        },
        timer(){
            if(this.sec<=0){
                this.setExchangeModal(false)
                clearInterval(this.intervel)
                return
            }else{
                this.sec --
            }
        },
        async exchange(){
          // let res = await this.reedemTicket()
          // if(res){
          //   this.$vaToast.init({ message: '兌換成功', color: 'success' })
          // }
          this.setErrorMsg({
            status: true,
            type: "exchange",
            title: "提醒",
            content: "此票券僅能使用一次，確定要使用票券嗎？",
          })
        },
    },
}
</script>
<style scoped lang='scss'>
@import "../assets/main.scss";
*{
  text-align: center;
}
.titleColor{
  color: $primaryColor;
}
.tk_status{
  margin: 32px 0 16px;
  :deep(.va-divider__text){
    padding-bottom: 20px;
    span{
      font-size: 16px;
      font-weight: medium;
      margin: 0 16px;
    }
  }
}
.caption{
  span{
    font-size: 24px;
    font-weight: bold;
    padding: 0 6px 0 4px;
  }
}
.qr{
  opacity: 0.3;
}
.stamp{
    @include center(85%,80%);
    width: 100px;
    height: 100px;
    filter: brightness(120%);
    transform: translate(-50%, -50%) rotate(25deg);
}
.colse_btn{
  width: 30%;
  white-space: nowrap;
}
.use{
  width: 100%;
  border-top: 1px dashed #ccc;
  border-bottom: 1px dashed #ccc;
  @include relativeCenter(120px);
  button{
    width: 50%;
    margin: 0 auto;
  }
}
</style>